import './layout.css'
import React from 'react'
import axios from 'axios'
import { navigate, Link } from 'gatsby'
import * as storage from '../utils/storage'
import { addDays } from '../utils/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import btn_rotate from '../images/button_rotate.svg'
import Layout from '../components/layout'

class CreateProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: storage.getItem('temp-email'),
      first_name: ``,
      last_name: ``,
      password: storage.getItem('temp-password'),
      photo: ``,
      gender: ``,
      mobile: '',
      public_contacts: 'mobile',
      location: 'sydney',
      thumb: null,
      rotateDegree: 360,
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  convertToBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.onloadend = function (e) {
      callback(e.target.result, e.target.error);
    };
    reader.readAsDataURL(file);
  };

  rotateBase64Image = (base64data, givenDegrees, callback) => {
    const degrees = givenDegrees % 360;
    if (degrees % 90 !== 0 || degrees === 0) {
      callback(base64data);
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = base64data;
    image.onload = function () {
      if (degrees === 180) {
        canvas.width = image.width;
        canvas.height = image.height;
      } else {
        canvas.width = image.height;
        canvas.height = image.width;
      }
      ctx.rotate(degrees * Math.PI / 180);
      if (degrees === 90) {
        ctx.translate(0, -canvas.width);
      } else if (degrees === 180) {
        ctx.translate(-canvas.width, -canvas.height);
      } else if (degrees === 270) {
        ctx.translate(-canvas.height, 0);
      }
      ctx.drawImage(image, 0, 0);
      callback(canvas.toDataURL());
    };
  }

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${Date.now()}${filename}`, { type: mime });
  }

  handleRotate = () => {
    this.setState({
      rotateDegree: this.state.rotateDegree + 90,
    });

    this.convertToBase64(this.state.photo, (data) => {
      this.rotateBase64Image(data, 90, (res) => {
        this.setState({
          photo: this.dataURLtoFile(res, 'avatar.png')
        });
      });
    });
  }

  handleFileChange = (event) => {
    this.setState({
      [event.target.name]: event.target.files[0],
      thumb: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleSubmit(event) {
    event.preventDefault()
    if (
      !this.state.mobile.match(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})\s*$/
      )
    ) {
      alert('Mobile is not a valid Australian number')
      return
    }
    for (let key in this.state) {
      if (key === 'loading') continue
      if (!this.state[key] || this.state[key] == '') {
        console.log('missing field', key)
        alert('Please complete all fields!')
        return
      }
    }

    this.setState({ loading: true })

    let formData = new FormData(event.target)
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
    // formData.append('degree', this.state.rotateDegree)
    formData.set('photo', this.state.photo);
    axios
      .post(`${process.env.API_BASE_URL}/api/user`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        // auto-login user
        axios
          .post(`${process.env.API_BASE_URL}/oauth/token`, {
            client_id: `${process.env.OAUTH_ID}`,
            client_secret: `${process.env.OAUTH_SECRET}`,
            grant_type: 'password',
            username: this.state.email,
            password: this.state.password,
          })
          .then(oauthRes => {
            storage.setItem('access_token', oauthRes.data.access_token)
            axios
              .post(
                `${process.env.API_BASE_URL}/graphql`,
                {
                  query: `query {
              me {
                  id
                  first_name
                  last_name
                  email
                  age
                  gender
                  mobile
                  location
                  photo_url
                  public_contacts
              }
            }`,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + oauthRes.data.access_token,
                  },
                }
              )
              .then(meRes => {
                const user = meRes.data.data.me
                storage.setItem('user', JSON.stringify(user))
                storage.setItem('expired_at', addDays(30))
                navigate('/events')
              })
              .catch(err => console.log(err))
          })
          .catch(err => {
            if (err.response) {
              alert(err.response.data.message)
            }
          })
      })
      .catch(err => {
        if (err.response) {
          const errors = err.response.data.errors
          for (let key in errors) {
            alert(errors[key][0])
            break
          }
        }

        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Layout>
        <h2 className="has-text-centered title is-3">Create Your Profile</h2>
        <form
          method="post"
          className="my-profile"
          onSubmit={e => this.handleSubmit(e)}
        >
          <div className="field">
            <label className="label">First Name*</label>
            <div className="control">
              <input
                className="input"
                name="first_name"
                type="text"
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Last Name*</label>
            <div className="control">
              <input
                className="input"
                name="last_name"
                type="text"
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Gender*</label>
            <div className="control">
              <label className="radio" style={{ left: '0' }}>
                <input
                  name="gender"
                  type="radio"
                  value="male"
                  onClick={e => this.handleChange(e)}
                />{' '}
                Male
              </label>
              <label className="radio" style={{ left: '0.7em' }}>
                <input
                  name="gender"
                  type="radio"
                  value="female"
                  onClick={e => this.handleChange(e)}
                />{' '}
                Female
              </label>
            </div>
          </div>
          <div className="field">
            <label className="label">Mobile*</label>
            <div className="control">
              <input
                className="input"
                name="mobile"
                type="text"
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">City*</label>
            <div className="control">
              <div className="select">
                <select name="location" onChange={e => this.handleChange(e)}>
                  <option value="sydney">Sydney</option>
                  <option value="melbourne">Melbourne</option>
                  <option value="brisbane">Brisbane</option>
                  <option value="perth">Perth</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">
              Contact Details to Share with Your Matches*
            </label>
            <div className="control">
              <div className="select">
                <select
                  name="public_contacts"
                  onChange={e => this.handleChange(e)}
                >
                  <option value="mobile">Show mobile only</option>
                  <option value="email">Show email only</option>
                  <option value="mobile,email">Show mobile and email</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label">Your Photo*</label>
            <div className="control">
              <div className="file">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="photo"
                    onChange={e => this.handleFileChange(e)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <FontAwesomeIcon icon={faUpload} />
                    </span>
                    <span className="file-label">Choose a file…</span>
                  </span>
                </label>
                {this.state.photo !== '' ? (
                  <span className="file-selected">
                    <img
                      style={{
                        height: '35px',
                        transform: 'rotate(' + this.state.rotateDegree + 'deg)',
                      }}
                      src={this.state.thumb}
                    />
                    <img
                      src={btn_rotate}
                      onClick={e => this.handleRotate(e)}
                      name="degree"
                      style={{
                        marginLeft: '5px',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        width: '24px',
                      }}
                    />
                  </span>
                ) : (
                  <span className="file-selected">No file selected</span>
                )}
              </div>
            </div>
          </div>
          <p>
            By clicking on the below button, I confirm that I have read and
            agree to the{' '}
            <a href="https://www.encounterdating.com.au/terms-conditions">
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href="https://www.encounterdating.com.au/privacy-policy">
              Privacy Policy
            </a>
          </p>
          <div className="fd-center" style={{ marginBottom: '50px' }}>
            <button
              type="submit"
              className={`button is-medium has-background-link has-text-white is-hidden-mobile ${
                this.state.loading ? 'is-loading' : ''
              }`}
            >
              Create my profile
            </button>
            <div className="fd-central">
              <button
                type="submit"
                className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${
                  this.state.loading ? 'is-loading' : ''
                }`}
              >
                Create my profile
              </button>
            </div>
          </div>
        </form>
      </Layout>
    )
  }
}

export default CreateProfile